/* eslint-disable */
import React, { useState } from 'react'

import { Typography, VariableContent } from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'
import header from '../components/Header'
import footer from '../components/Footer'
import PolicyForm from '../components/PolicyForm'
import { Form } from '@leshen/ui'
import { navigate } from 'gatsby'

const Home = () => {
  const [showSubmissionMessage, setShowSubmissionMessage] = useState(false)
  const [error, setError] = useState('')

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: 'Clearlink Insurance Agency | Insurance Quotes | 833-488-0038',
          description:
            'Call Clearlink Insurance Agency - Your one-stop-shop for Auto, Home, Supplemental Health, & Medicare Insurance! Our Agents are ready to provide you quotes from multiple partners in order to get you the rate you deserve.',
          canonical: 'https://clearlinkinsurance.com/confirmation',
          robots: 'follow,index',
        },
        path: '/confirmation',
        promoCode: '169447',
        ringPool: 'CLI',
        hideStickyCTA: false,
        spanish: false,
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlinkinsurance.com/',
          siteName: 'clearlinkinsurance',
          alternateName: 'clearlinkinsurance',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'clearlinkinsurance',
    defaultRobots: 'follow,index',
    main: (
      <>
        <div className="purple-triangle-edge-background white-text">
          <VariableContent
            className="policy-form"
            mainContent={
              <>
                <Typography variant="h1">Policy Tracker</Typography>
                <Typography variant="h4">
                  View your policy status.
                  <br />
                  Enter your enrollment code.
                </Typography>
                <Typography variant="body" className="medium">
                  Policies written by Clearlink Insurance receive an email with
                  a confirmation code
                </Typography>
              </>
            }
            alignMainContent="center"
          >
            <div className="policy-check-form">
              <Form
                formId={null}
                formSource="eloqua"
                submitButtonText="Submit"
                submitButtonColor="primary"
                submitButtonOutlined={false}
                submitButtonGradient={false}
                inlineButton={false}
                formName={null}
                onSubmit={async ({ values }) => {
                  navigate(`/policy-status?enrollment_code=${values.code}`)
                }}
                emilyTCPAUpdate={() => {}}
                steps={null}
                showSubmissionMessage={showSubmissionMessage}
                fields={[
                  { name: 'code', type: 'text', label: 'Enrollment Code' },
                ]}
                isMultiStep={false}
                subheading={null}
                tcpaCheckbox={false}
                disclaimer={null}
                initialStepValues={{}}
                smartyStreetsWebsiteKey={null}
                addressQualificationCode={null}
                spanish={false}
                requestId={null}
              />{' '}
            </div>
          </VariableContent>
        </div>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home
