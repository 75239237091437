import React, { useState } from 'react'
import { navigate } from 'gatsby'

const PolicyForm = () => {
  const [inputValue, setInputValue] = useState('')
  const [error, setError] = useState('')

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
    setError('')
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    // Validation to check for spaces in the input
    const spacesChecker = /\s/

    if (spacesChecker.test(inputValue)) {
      setError('Please check your confirmation code')
    } else {
      // Redirect to the new page with the value appended to the URL
      navigate(`/policy-status?enrollment_code=${inputValue}`)
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          id="policyInputField"
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Input Code"
        />
        {error && <p className="error">{error}</p>}

        <button type="submit">Submit</button>
      </form>
    </div>
  )
}

export default PolicyForm
